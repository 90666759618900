<template>
  <div>
    <van-nav-bar title="开始标记你的路线吧~" />

    <div id="processboard" class="container">
      <div class="myImage">
        <canvas
          id="myCanvas"
          ref="myCanvas"
          width="400"
          height="400"
          @touchstart="touchstart"
          @touchend="touchend"
          @touchmove="touchmove"
        >
        </canvas>
      </div>

      <div v-show="isShow" class="colorSelect">
        <div v-show="!chosenBlack" @click="changeBlack" class="black">
          <div style="background: black"></div>
        </div>
        <span v-show="chosenBlack" @click="changeBlack" class="black">
          <div style="background: black"></div>
        </span>

        <div v-show="!chosenRed" @click="changeRed" class="red">
          <div style="background: red"></div>
        </div>
        <span v-show="chosenRed" @click="changeRed" class="red">
          <div style="background: red"></div>
        </span>

        <div v-show="chosenBlue" @click="changeBlue" class="blue">
          <div style="background: blue"></div>
        </div>
        <span v-show="!chosenBlue" @click="changeBlue" class="blue">
          <div style="background: blue"></div>
        </span>

        <div v-show="!chosenGreen" @click="changeGreen" class="green">
          <div style="background: green"></div>
        </div>
        <span v-show="chosenGreen" @click="changeGreen" class="green">
          <div style="background: green"></div>
        </span>

        <div v-show="!chosenYellow" @click="changeYellow" class="yellow">
          <div style="background: yellow"></div>
        </div>
        <span v-show="chosenYellow" @click="changeYellow" class="yellow">
          <div style="background: yellow"></div>
        </span>

        <div style="background: #f4f4f4" class="cancel" @click="clearArea">
          <van-icon name="revoke" size="20" />
        </div>
      </div>

      <div id="btnContainer">
        <!-- 起步 -->
        <div>
          <van-button plain :icon="icon1" @click="triangle" class="tubiao">
            起步
          </van-button>
        </div>
        <!-- 途径 -->
        <div>
          <van-button plain :icon="icon2" @click="circle" class="tubiao">
            途径
          </van-button>
        </div>
        <!-- 结束 -->
        <div>
          <van-button plain :icon="icon3" @click="rectangle" class="tubiao">
            结束
          </van-button>
        </div>
      </div>

      <div class="complete">
        <van-button class="next" @click="save" type="info">完成</van-button>
      </div>
    </div>
  </div>
</template>


<script>
import { type } from "os";

export default {
  name: "markRoute",
  data() {
    return {
      nowDate: { date: "----", time: "--", week: "--" },
      scrollerHeight1: "90%",
      scrollerHeight2: "70%",
      showarrow: "none",
      img: new Image(),
      img1: new Image(),
      img2: new Image(),
      img3: new Image(),
      x: 0,
      y: 0,
      oldX: 0,
      oldY: 0,
      xOffset: 0,
      yOffset: 0,
      SignImage: "",
      icon1: require("../assets/images/triangle.png"),
      icon2: require("../assets/images/circle.png"),
      icon3: require("../assets/images/square.png"),
      triangleImgRed: require("../assets/images/triangleRed.png"),
      circleImgRed: require("../assets/images/circleRed.png"),
      squareImgRed: require("../assets/images/squareRed.png"),
      triangleImgYellow: require("../assets/images/triangleYellow.png"),
      circleImgYellow: require("../assets/images/circleYellow.png"),
      squareImgYellow: require("../assets/images/squareYellow.png"),
      triangleImgGreen: require("../assets/images/triangleGreen.png"),
      circleImgGreen: require("../assets/images/circleGreen.png"),
      squareImgGreen: require("../assets/images/squareGreen.png"),
      circleImgBlack: require("../assets/images/circleBlack.png"),
      squareImgBlack: require("../assets/images/squareBlack.png"),
      triangleImgBlack: require("../assets/images/triangleBlack.png"),
      currentCameraUrl: "",
      drawArea: false,
      canDraw: false,
      roundDraw: false,
      triangleDraw: false,
      signSrc: "",
      history: [],
      imageData: null,
      context: {},
      canvasHight: 0,
      canvasWidth: 0,
      finished: false,
      DefaultColor: true,
      isShow: false,
      chosenBlack: false,
      chosenRed: false,
      chosenBlue: false,
      chosenGreen: false,
      chosenYellow: false,
    };
  },
  mounted() {
    this.currentCameraUrl = this.$route.params.pic;

    this.initDraw();
  },
  methods: {
    imageProcessor(dataurl, filename = "file") {
      if (dataurl) {
        var decode = atob(base64);
        // 编码转字符串
        var str = decodeURI(decode);

        let arr = aaa.spilt(",");
        console.log(arr);
        let mime = arr[0].match(/:(.*?);/)[1];

        let suffix = mime.split("/")[1];

        let bstr = atob(arr[1]);

        let n = bstr.length;

        let u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], `${filename}.${suffix}`, {
          type: mime,
        });
      }
    },

    initDraw() {
      // 初始化画布
      const canvas = this.$refs.myCanvas;

      var a = setInterval(() => {
        if (!canvas) {
          // console.log(211)
          return false;
        } else {
          clearInterval(a);
          this.context = canvas.getContext("2d");
          this.img.src = this.currentCameraUrl;
          this.img.crossOrigin = "Anonymous";
          this.img.onload = () => {
            if (this.img.complete) {
              //根据图像重新设定canvas宽高
              // console.log("dada",this.img.height/this.img.width)

              if (
                this.img.height / this.img.width > 0.95 &&
                this.img.height / this.img.width < 1.05
              ) {
                this.canvasHight = 390;
                this.canvasWidth = 390;
              } else if (this.img.height > this.img.width) {
                this.canvasHight = 520;
                this.canvasWidth = 390;
              } else if (this.img.height < this.img.width) {
                this.canvasHight = 293;
                this.canvasWidth = 390;
              }
              canvas.setAttribute("width", this.canvasWidth);
              canvas.setAttribute("height", this.canvasHight);
              //绘制图片
              this.context.drawImage(
                this.img,
                0,
                0,
                this.canvasWidth,
                this.canvasHight
              );
              this.imageData = this.context.getImageData(
                0,
                0,
                this.canvasWidth,
                this.canvasHight
              );
              this.history.push(this.imageData);
              //console.log(this.history.length)
            }
          };
        }
      }, 1);
    },

    //矩形
    rectangle: function () {
      this.SignImage = "";
      this.DefaultColor = true;
      this.canDraw = true;
      this.roundDraw = false;
      this.triangleDraw = false;
      this.isShow = true;
    },
    //圆
    circle: function () {
      this.SignImage = "";
      this.DefaultColor = true;
      this.canDraw = false;
      this.roundDraw = true;
      this.triangleDraw = false;
      this.isShow = true;
    },

    //三角
    triangle: function () {
      this.SignImage = "";
      this.DefaultColor = true;
      this.canDraw = false;
      this.roundDraw = false;
      this.triangleDraw = true;
      this.isShow = true;
    },

    cancel: function () {
      this.SignImage = "";
      this.DefaultColor = true;
      this.canDraw = false;
      this.roundDraw = false;
      this.triangleDraw = false;
    },

    changeRed: function (e) {
      this.chosenBlack = false;
      this.chosenBlue = true;
      this.chosenGreen = false;
      this.chosenYellow = false;
      this.chosenRed = !this.chosenRed;
      if (this.canDraw) {
        this.SignImage = this.squareImgRed;
      } else if (this.triangleDraw) {
        this.SignImage = this.triangleImgRed;
      } else {
        this.SignImage = this.circleImgRed;
      }
      this.DefaultColor = false;
    },
    changeYellow: function (e) {
      this.chosenBlack = false;
      this.chosenBlue = true;
      this.chosenGreen = false;
      this.chosenRed = false;
      this.chosenYellow = !this.chosenYellow;
      if (this.canDraw) {
        this.SignImage = this.squareImgYellow;
      } else if (this.triangleDraw) {
        this.SignImage = this.triangleImgYellow;
      } else {
        this.SignImage = this.circleImgYellow;
      }
      this.DefaultColor = false;
    },
    changeBlue: function (e) {
      this.chosenBlack = false;
      this.chosenRed = false;
      this.chosenGreen = false;
      this.chosenRed = false;
      this.chosenBlue = !this.chosenBlue;
      if (this.canDraw) {
        this.SignImage = this.icon3;
      } else if (this.triangleDraw) {
        this.SignImage = this.icon1;
      } else {
        this.SignImage = this.icon2;
      }
      this.DefaultColor = false;
    },
    changeGreen: function (e) {
      this.chosenBlack = false;
      this.chosenBlue = true;
      this.chosenYellow = false;
      this.chosenRed = false;
      this.chosenGreen = !this.chosenGreen;
      if (this.canDraw) {
        this.SignImage = this.squareImgGreen;
      } else if (this.triangleDraw) {
        this.SignImage = this.triangleImgGreen;
      } else {
        this.SignImage = this.circleImgGreen;
      }
      this.DefaultColor = false;
    },
    changeBlack: function (e) {
      this.chosenYellow = false;
      this.chosenBlue = true;
      this.chosenGreen = false;
      this.chosenRed = false;
      this.chosenBlack = !this.chosenBlack;
      if (this.canDraw) {
        this.SignImage = this.squareImgBlack;
      } else if (this.triangleDraw) {
        this.SignImage = this.triangleImgBlack;
      } else {
        this.SignImage = this.circleImgBlack;
      }
      this.DefaultColor = false;
    },

    //保存图片
    save: function () {
      if (this.history.length == 1) {
        alert("请绘制检测区");
        return;
      }
      const imgBase64 = this.$refs.myCanvas.toDataURL("image/png");
      this.signSrc = imgBase64;
      sessionStorage.setItem("pic2", this.signSrc);
      this.$router.push({
        name: "upload",
        params: {
          signSrc: this.signSrc,
        },
      });
    },

    touchstart: function (e) {
      this.drawArea = true;
      if (this.canDraw) {
        let vertex = document
          .getElementById("myCanvas")
          .getBoundingClientRect();
        this.x = e.touches[0].pageX - vertex.left - 20;
        this.y = e.touches[0].pageY - vertex.top - 20;
        this.drawRectWithParam(e);
      }
      if (this.roundDraw) {
        let vertex = document
          .getElementById("myCanvas")
          .getBoundingClientRect();
        this.x = e.touches[0].pageX - vertex.left - 20;
        this.y = e.touches[0].pageY - vertex.top - 20;
        this.round(e);
      }
      if (this.triangleDraw) {
        let vertex = document
          .getElementById("myCanvas")
          .getBoundingClientRect();
        this.x = e.touches[0].pageX - vertex.left - 20;
        this.y = e.touches[0].pageY - vertex.top - 20;
        // console.log(this.x,this.y)
        this.DrawTriangle(e);
      }

      if (this.finished) {
        this.imageData = this.context.getImageData(
          0,
          0,
          this.canvasWidth,
          this.canvasHight
        );
        this.drawArea = false;
        this.history.push(this.imageData);
      }
      this.finished = false;
    },
    touchend: function (e) {},

    touchmove: function (e) {},

    //绘制矩形
    drawRectWithParam(e) {
      const canvas = this.$refs.myCanvas;
      var ctx = canvas.getContext("2d");
      // ctx.clearRect(0,0,canvas.width,canvas.height);
      // this.changeRed()

      if (this.DefaultColor) {
        this.img2.src = this.icon3;
      } else {
        this.img2.src = this.SignImage;
      }

      this.img2.onload = () => {
        if (this.img2.complete) {
          // console.log("上传成功！！",this.img1)
          //绘制图片
          ctx.drawImage(this.img2, this.x, this.y, 30, 30);
          // console.log(this.history.length)
        }
      };
      this.finished = true;
    },

    // 绘制圆形
    round(e) {
      const canvas = this.$refs.myCanvas;
      var ctx = canvas.getContext("2d");
      // ctx.clearRect(0,0,canvas.width,canvas.height);
      if (this.DefaultColor) {
        this.img3.src = this.icon2;
      } else {
        this.img3.src = this.SignImage;
      }
      this.img3.onload = () => {
        if (this.img3.complete) {
          // console.log("上传成功！！",this.img1)
          //绘制图片
          ctx.drawImage(this.img3, this.x, this.y, 25, 25);
          // console.log(this.history.length)
        }
      };
      this.finished = true;
    },

    // 三角形
    DrawTriangle(e) {
      const canvas = this.$refs.myCanvas;
      var ctx = canvas.getContext("2d");
      // ctx.clearRect(0,0,canvas.width,canvas.height);
      if (this.DefaultColor) {
        this.img1.src = this.icon1;
      } else {
        this.img1.src = this.SignImage;
      }
      this.img1.onload = () => {
        if (this.img1.complete) {
          // console.log("上传成功！！",this.img1)
          //绘制图片
          ctx.drawImage(this.img1, this.x, this.y, 30, 30);
          // console.log(this.history.length)
        }
      };
      this.finished = true;
    },

    //回退一步
    clearArea: function () {
      const canvas = this.$refs.myCanvas;
      this.context.putImageData(this.history[this.history.length - 1], 0, 0);
      var ctx = canvas.getContext("2d");
      if (this.history.length > 1) {
        this.history.pop();
      }
      // console.log("删除后的长度为",this.history.length)
    },
  },
};
</script>

<style scoped>
.container {
  /* position: absolute; */
  background: #e0dfdf;
  min-height: 100vh;
  z-index: 200;
}

#processboard {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #f4f4f4;
}

#myCanvas {
  background-color: #dbdde1;
  width: 100%;
}

#btnContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin-top: 15px;
}

#btnContainer div {
  margin: 0 auto;
}

.tubiao {
  position: relative;
  box-sizing: border-box;
  width: 85px;
  height: 40px;
}

.image-1 {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -5px;
  margin-bottom: auto;
  width: 23px;
  height: 23px;
  line-height: 23px;
  font-size: 15px;
}
.image-1 img {
  width: 100%;
  /* margin-right: -10px; */
}

a {
  text-decoration: none;
  color: #000;
}

.complete {
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.myImage {
  margin-top: 20px;
}

.colorSelect {
  box-sizing: border-box;
  /* overflow: scroll; */
  display: flex;
  padding: 10px;
  position: relative;
  width: 100%;
  margin: 10px;
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.colorSelect div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  background: white;
  overflow: hidden;
  border-radius: 50%;
}

.colorSelect span {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background: white;
  overflow: hidden;
  border-radius: 50%;
}
.colorSelect span div {
  width: 15px;
  height: 15px;
  border: 50%;
}

.colorSelect div div {
  width: 15px;
  height: 15px;
  border: 50%;
}

.next {
  height: 35px;
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  font-size: 20px;
  display: flex;
  border-radius: 15px;
}
</style>